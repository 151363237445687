/* eslint-disable react/self-closing-comp */
import { FC, useRef, useState } from 'react'
import { BasicStyledComponent } from 'shared-definitions/types'
import clsx from 'clsx'
import Bell from 'shared-svg/Bell.svg'
import styles from './NotificationsBell.module.css'
import { useMutationObserver } from '../hooks/use-mutation-observer'
import composeRefs from '@seznam/compose-react-refs'

const NotificationsBell: FC<BasicStyledComponent> = ({ className }) => {
  const [followingCount, setFollowingCount] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const boxRef = useMutationObserver<HTMLDivElement>(
    {
      characterData: true,
      childList: true,
      subtree: true,
    },
    () => {
      if (boxRef.current) {
        const vfCounter = boxRef.current.querySelector('button > strong > span')
        if (vfCounter) {
          setFollowingCount(parseInt(vfCounter.textContent ?? '0', 10))
        } else {
          setFollowingCount(0)
        }
      }
    }
  )

  return (
    <div className={className}>
      <div className={clsx('viafoura', styles.widget)} ref={composeRefs(ref, boxRef)}>
        <vf-tray-trigger floating="false"></vf-tray-trigger>
      </div>
      <button type="button" className={styles.button}>
        <Bell
          className={styles.icon}
          onClick={() => {
            ref.current?.querySelector('button')?.click()
          }}
        />
        {followingCount > 0 ? <span className={styles.count}>{followingCount}</span> : null}
      </button>
    </div>
  )
}

export default NotificationsBell
