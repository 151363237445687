/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, type DependencyList } from 'react'

/**
 * This hook is used to prevent the execution of an async action
 * if the component is unmounted before the action is completed
 * or if the action is called again.
 * @param effect
 * @param deps
 */
export function useAbortableEffect(
  effect: (abortSignal: AbortSignal) => Promise<void>,
  deps: DependencyList
): void {
  const abortController = useRef<AbortController | null>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    // timeout to prevent multiple aborts in a short time
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      abortController.current = new AbortController()
      void effect(abortController.current.signal).catch(e => {
        console.error(e)
      })
    }, 10)

    return () => {
      abortController.current?.abort()
    }
  }, deps)
}
