import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './CommentsCount.module.css'
import clsx from 'clsx'
import Comment from 'shared-svg/CommentHollow.svg'
// import ViafouraCommentsCounter from '../service/ViafouraCommentsCounter'

interface CommentsCountProps extends BasicStyledComponent {
  containerId: string
  size?: 'small' | 'medium'
}

const CommentsCount: React.FC<CommentsCountProps> = ({
  className,
  // containerId,
  size = 'medium',
}) => (
  <div
    className={clsx(styles.container, className, {
      [styles.sizeMedium]: size === 'medium',
      [styles.sizeSmall]: size === 'small',
    })}
  >
    <Comment className={styles.icon} />
    {/* <ViafouraCommentsCounter containerId={containerId} /> */}
  </div>
)

export default CommentsCount
