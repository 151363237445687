/* eslint-disable promise/prefer-await-to-callbacks */
import { useEffect, useRef, useState } from 'react'
import { EventBus } from 'shared-code/event-bus'
import { createContainer } from 'shared-components/unstated'
import { NotificationsContext } from './NotificationsContext'
import { AuthState, AuthStorage, AuthStorageProps } from 'shared-code/auth-storage'
import './AuthContext.css'

export interface AuthValueProps {
  clientId?: string
  domain?: string
}

type AuthEventCleanup = () => void

type AuthEvent = 'session-updated' | 'logout'

export interface UpdateProfileProps {
  name: string
}

export interface AuthContextProps {
  authState: AuthState | null
  showLogIn: () => Promise<void>
  showSignUp: () => Promise<void>
  logout: () => Promise<void>
  onAuthEvent: (event: AuthEvent, callback: () => void) => AuthEventCleanup
  updateProfile(profile: UpdateProfileProps): Promise<void>
  updateAuthState(): Promise<void>
}

const eventBus = new EventBus<AuthEvent>()

// Docs:
// https://community.auth0.com/t/lock-based-authentication-setup/6584
// https://auth0.com/docs/libraries/lock
// https://auth0.com/docs/libraries/lock/lock-api-reference
export const AuthContext = createContainer<AuthContextProps, AuthValueProps>(initial => {
  if (!initial) {
    throw new Error('Undefined Auth0 initial props')
  }
  const storageRef = useRef<AuthStorageProps>(new AuthStorage())
  const [authState, setAuthState] = useState<AuthState | null>(null)
  const { onAuthorized } = useOnAuthorized(storageRef.current)
  const { getLockInstance } = useGetLockInstance(initial, onAuthorized)

  const getState = async (forceUpdate?: boolean): Promise<AuthState | null> => {
    const lock = await getLockInstance()
    if (!forceUpdate && storageRef.current.isValidSession()) {
      // console.log('auth0x21', { forceUpdate })
      return storageRef.current.getAuthState()
    }

    // console.log('auth0x22')
    return new Promise((resolve, reject) => {
      // console.log('auth0x23')
      lock.checkSession({ redirectUri: window.location.origin }, (error, authResult) => {
        if (error) {
          reject(error)
          return
        }
        if (authResult) {
          void onAuthorized(lock, authResult)
            .then(() => {
              resolve(storageRef.current.getAuthState())
            })
            .catch(e => console.error(e))
        } else {
          resolve(null)
        }
      })
    })
  }

  useEffect(() => {
    void getState(true)
      .then(state => {
        // console.log('auth0x7')
        setAuthState(state)
      })
      .catch(e => console.error(e))
  }, [])

  useEffect(() => {
    const eb = eventBus
    // console.log('auth0x5')
    const handler = (): void => {
      const nState = storageRef.current.getAuthState()
      // console.log('auth0x6', nState)
      setAuthState(nState)
    }
    eb.on('session-updated', handler)
    return () => {
      eb.off('session-updated', handler)
    }
  }, [])

  function fixPlaywireIssues(): void {
    const wrapper = document.querySelector('main.auth0-lock-container')
    if (wrapper) {
      wrapper.setAttribute('data-container-type', 'content')
    }
  }

  return {
    authState,
    updateAuthState(): Promise<void> {
      return getState(true).then(state => {
        // console.log(' auth0x20', { state })
        setAuthState(state)
      })
    },
    onAuthEvent: (event, callback) => {
      eventBus.on(event, callback)

      return () => {
        eventBus.off(event, callback)
      }
    },
    showLogIn: async () => {
      const lock = await getLockInstance()
      lock.show({
        initialScreen: 'login',
      })
      fixPlaywireIssues()
    },
    showSignUp: async () => {
      const lock = await getLockInstance()
      lock.show({
        initialScreen: 'signUp',
      })
      fixPlaywireIssues()
    },
    logout: async () => {
      eventBus.emit('logout')
      const lock = await getLockInstance()
      storageRef.current.clearAuthResult()
      setAuthState(null)
      lock.logout({
        returnTo:
          window.location.origin + '/api/logout/?url=' + encodeURI(window.location.pathname),
      })
    },
    // Update the profile on Auth0 side
    updateProfile: async (profile: UpdateProfileProps) => {
      if (!authState?.idToken) {
        throw new Error('No idToken')
      }

      const response = await fetch('/api/update-profile/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authState.idToken}`,
        },
        body: JSON.stringify(profile),
      })
      if (!response.ok) {
        throw new Error('Error updating profile')
      }
    },
  }
})

function useGetLockInstance(
  initial: AuthValueProps,
  onAuthorized: (lock: Auth0LockPasswordlessStatic, authResult: AuthResult) => Promise<void>
): {
  getLockInstance: () => Promise<Auth0LockPasswordlessStatic>
} {
  const lockRef = useRef<Auth0LockPasswordlessStatic>()
  async function getLockInstance(): Promise<Auth0LockPasswordlessStatic> {
    if (!initial?.clientId) {
      throw new Error('Undefined Auth0 initial.clientId')
    }
    if (!initial?.domain) {
      throw new Error('Undefined Auth0 initial.domain) {')
    }

    const { Auth0LockPasswordless } = await import('auth0-lock')

    if (!lockRef.current) {
      lockRef.current = new Auth0LockPasswordless(initial.clientId, initial.domain, {
        languageDictionary: {
          signUpTerms:
            'By signing up, you agree to our <a href="/privacy-policy/">Privacy Policy</a> and European users agree to the data transfer policy.',
          title: '',
        },
        theme: {
          authButtons: undefined,
          hideMainScreenTitle: true,
          logo: '/static/auth0_logo.svg',
          primaryColor: 'rgb(var(--p-70))',
        },
        auth: {
          redirectUrl: window.location.origin,
          responseType: 'token id_token',
          redirect: false,
          sso: false,
          params: {
            scope: 'openid profile email',
          },
        },
      })

      lockRef.current.on('authenticated', authResult => {
        if (lockRef.current) {
          void onAuthorized(lockRef.current, authResult).catch(e => console.error(e))

          lockRef.current.hide()
        }
      })
    }

    return lockRef.current
  }
  return { getLockInstance }
}

function useOnAuthorized(storage: AuthStorageProps): {
  onAuthorized: (lock: Auth0LockPasswordlessStatic, authResult: AuthResult) => Promise<void>
} {
  const { enqueue } = NotificationsContext.useContainer()
  function onAuthorized(lock: Auth0LockPasswordlessStatic, authResult: AuthResult): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log('auth0x1', authResult)
      lock.getUserInfo(authResult.accessToken, (error, profile) => {
        console.log('auth0x2', authResult, profile)
        if (error) {
          // console.log('auth0x3', authResult, profile, error)
          enqueue({ msg: 'Error loading the Profile', variant: 'error', duration: 3000 })
          reject(error)
          return
        }

        // console.log('auth0x4', authResult, profile, error)
        storage.saveAuthResult(authResult, profile)
        eventBus.emit('session-updated')
        resolve()
      })
    })
  }

  return { onAuthorized }
}
