/* eslint-disable react/self-closing-comp */
import Head from 'next/head'
import { FC } from 'react'
import { useViafoura } from '../hooks/useViafoura'
import './ViafouraStyles.css'

const ViafouraScript: FC = () => {
  useViafoura()

  return (
    <Head>
      <script async src="//cdn.viafoura.net/entry/index.js" />
    </Head>
  )
}

export default ViafouraScript
