import { useEffect } from 'react'
import { NotificationsContext } from '../contexts/NotificationsContext'
import { useRouter } from 'next/router'
import { AuthContext } from '../contexts/AuthContext'

// Docs: https://documentation.viafoura.com/docs/openidconnect-integration
// Docs: https://documentation.viafoura.com/docs/viafoura-javascript-api-2
export function useViafoura(): void {
  const { onAuthEvent, showLogIn, logout, authState } = AuthContext.useContainer()
  const { enqueue } = NotificationsContext.useContainer()
  const router = useRouter()

  // reset Viafoura viafoura on route change
  // Docs: https://documentation.viafoura.com/docs/single-page-app-setup
  useEffect(() => {
    const handleRouteChange = (): void => {
      window.vfQ = window.vfQ || []

      window.vfQ.push(() => {
        void window.vf?.context.reset()
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    //
  }, [])

  useEffect(
    () =>
      onAuthEvent('logout', () => {
        window.vf?.session.logout()
      }),
    []
  )

  useEffect(() => {
    window.vfQ = window.vfQ || []

    // trigger login flow when authentication is required
    window.vfQ.push(() => {
      window.vf?.$prepublish((channel, event, ...args) => {
        if (channel === 'authentication' && event === 'needed') {
          window.vf?.$publish('tray', 'close')

          void showLogIn()

          return false
        }

        return { channel, event, args }
      })
    })

    // subscribe to logout event
    window.vfQ.push(() => {
      window.vf?.$subscribe('authentication', 'logout', () => {
        void logout()
      })
    })

    // show ban message
    window.vf?.$subscribe('login', 'failure', errorContext => {
      if (errorContext.error_key === 'login_banned' && errorContext.isPermanent === '1') {
        enqueue({
          msg: 'You have been permanently banned from commenting. Reason: ' + errorContext.reason,
          variant: 'error',
          duration: 5000,
        })
      } else if (errorContext.error_key === 'login_banned' && errorContext.isPermanent === '0') {
        enqueue({
          msg:
            'You have been temporarily banned from commenting until ' +
            errorContext.endDate +
            '. Reason: ' +
            errorContext.reason,
          variant: 'error',
          duration: 5000,
        })
      }
    })

    return () => {
      // unsubscribe from logout event
      window.vf?.$unsubscribe('authentication', 'logout')

      // unsubscribe from login failure event
      window.vf?.$unsubscribe('login', 'failure')
    }
  }, [])

  // Send ID token to Viafoura on page load.
  // This should be enough to keep the user logged in,
  // because we will redirect to a separate login page when authentication is required,
  // and after login, the page will be reloaded.
  useEffect(() => {
    window.vfQ = window.vfQ || []
    if (authState) {
      //
      window.vfQ.push(() => {
        console.log('Viafoura id token update')
        window.vf?.session.login.openIdConnect(authState.idToken)
      })
    }
  }, [authState])
}
